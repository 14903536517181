<template>
  <div class="location">

    <div class="location-intro">
      <div class="row row-gutter-20">
        <div class="col-12 location-details">
          <h1>{{ location.name }}</h1>
          <p><span class="material-icons">gps_fixed</span><span>{{ location.street_name }} {{ location.street_number }}</span></p>
          <p><span>{{ location.zip_code }} {{ location.city }}</span></p>
          <p><span class="material-icons">phone</span><span>{{ location.contact_phone }}</span></p>
          <p><span class="material-icons">mail</span><span>{{ location.contact_email }}</span></p>
          <p style="margin: 25px 0"><a :href="'https://www.google.com/maps/place/'+location.street_name+'+'+location.street_number+'+'+location.zip_code+'+'+location.city" target="_blank" style="color: #444;font-weight:600">Anfahrt bei Google Maps ansehen ›</a></p>
          <div v-if="location.description" style="margin-top:30px;white-space: pre-wrap">{{ location.description }}</div>
        </div>
        <div class="col-12">
          <img :src="location.image">
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'locations',
  data () {
    return {
      location: {},
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/locations/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.location = response.data.location;
      })
      .catch(error => {
        console.log(error);
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
